import { Service } from './service'

const PATH = 'invoices'

class InvoiceService extends Service {
  getInvoices () {
    return this.get(PATH)
  }

  seeInvoice (params = {}) {
    return this.post(PATH, params)
  }
}

export default new InvoiceService()
